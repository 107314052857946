<template>
    <b-sidebar
    ref="mySidebar"
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="val => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <template slot-scope="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Yeni Kişi Ekle 
            <!-- selectedepartman{{ selectedDepartment }}{{ departmentList }} -->
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer ref="simpleRules"       
 >
          <!-- Form -->
          <b-form
            class="p-2"
          
          >
  
            <!-- Full Name -->
            <validation-provider
            #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Ad"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="userData.name"
                  autofocus
                  :state="errors.length > 0 ? false:null"
                  trim
                  placeholder=""
                />
  
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
  
            <!-- Username -->
            <validation-provider
            #default="{ errors }"
              name="lastname"
              rules="required|alpha-num"
            >
              <b-form-group
                label="Soyad"
                label-for="lastname"
              >
                <b-form-input
                  id="lastname"
                  v-model="userData.surname"
                  :state="errors.length > 0 ? false:null"
                  trim
                />
  
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
  
            <!-- Email -->
            <validation-provider
            #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="errors.length > 0 ? false:null"
                  trim
                />
  
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
            #default="{ errors }"
              name="Departman"
              rules="required"
            >
            <b-form-group
                label="Departman"
                label-for="departman"
              >
            <div>
               {{ selectedDepartment}}
    <b-form-select
    id="departman"
    v-if="departmentList"
      v-model="selectedDepartment"
      :options="options"
      :state="selectedDepartment === null ? false : true"
    />
    <!-- <b-card-text class="mt-1 mb-0">
      Selected: <strong>{{ selected }}</strong>
    </b-card-text> -->
  </div> </b-form-group>
  <small class="text-danger">{{ errors[0] }}</small>
</validation-provider>
            <!-- Company -->
            <validation-provider
            #default="{ errors }"
              name="Contact"
              rules="required"
            >
              <b-form-group
                label="Telefon"
                label-for="contact"
              >
                <b-form-input
                id="contact"
  type="tel"
  v-model="userData.phoneNo"
  pattern="[0-9]+"
  :state="isValidPhoneNumber(userData.phoneNo) ? true : false"
  trim
                />
  
                <b-form-invalid-feedback>
                  Lütfen geçerli bir telefon numarası giriniz!{{ errors[0] }}
                </b-form-invalid-feedback>
                <!-- <small class="text-danger"> Lütfen geçerli bir telefon numarası giriniz!{{ errors[0] }}</small> -->
              </b-form-group>
            </validation-provider>
  <!-- {{ userData }} -->
            <!-- Company -->
            <validation-provider
            v-if="userRole.generalRole === 'Admin'"
            #default="{ errors }"
              name="Company"
              rules="required"
            >
              <b-form-group
                label="Admin mi?"
                label-for="company"
              >
                <!-- <b-form-input
                  id="company"
                  v-model="userData.isAdmin"
                :state="errors.length > 0 ? false:null"
                trim
              /> -->
            
     <b-form-radio-group
     id="company"
     v-model="userData.generalRole"
     :options="[{ value: true, text: 'Evet' }, { value: false, text: 'Hayır' }]"

      :state="errors.length > 0 ? false:null"
      trim
      class="demo-inline-spacing mb-1"
      value-field="value"
      text-field="text"
      disabled-field="disabled"
    />
 

    <!-- <b-card-text class="mt-1 mb-0">
      Selected: <strong>{{ userData.isAdmin }}</strong>
    </b-card-text> -->

    <small class="text-danger">{{ errors[0] }}</small>

            </b-form-group>
          </validation-provider>
  <!-- Company -->
  
  <validation-provider
  v-if="userRole.generalRole === 'Admin'"
            #default="{ errors }"
              name="Company"
              rules="required"
            >
              <b-form-group
                label="Departman Yöneticisi mi?"
                label-for="company-2"
              >
                <!-- <b-form-input
                  id="company"
                  v-model="userData.isAdmin"
                :state="errors.length > 0 ? false:null"
                trim
              /> -->
            
   
    <b-form-radio-group
  id="company-2"
  v-model="userData.departmentRole"
  :options="[{ value: true, text: 'Evet' }, { value: false, text: 'Hayır' }]"
  :state="errors.length > 0 ? false : null"
  trim
  class="demo-inline-spacing mb-1"
  value-field="value"
  text-field="text"
  disabled-field="disabled"
/>

    <!-- <b-card-text class="mt-1 mb-0">
      Selected: <strong>{{ userData.isAdmin }}</strong>
    </b-card-text> -->

    <small class="text-danger">{{ errors[0] }}</small>

            </b-form-group>
          </validation-provider>
          <!-- Password -->
          <!-- <validation-provider
          #default="{ errors }"
            name="Password"
            rules="required|min:6"
          >
            <b-form-group
              label="Şifre"
              label-for="password"
            >
              
              <div>
    <b-form-input
      id="password"
      autocomplete="on"
      :type="showPassword ? 'text' : 'password'"
      v-model="userData.password"
      :state="errors.length > 0 ? false : null"
      trim
    />
    <label for="showPassword">Şifreyi Göster</label>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="btn-icon rounded-circle ml-1"
      size="sm"
      @click="showPassword = !showPassword" v-model="showPassword"
    >
 
      <feather-icon icon="EyeIcon" />
    </b-button>
 
   
  </div>
  <small class="text-danger">{{ errors[0] }}</small>

            </b-form-group>
          </validation-provider> -->

          <!-- Confirm Password -->
          <!-- <validation-provider
          #default="{ errors }"
            name="Confirm Password"
            rules="required|confirmed:Password"
          >
            <b-form-group
              label="Şifre Tekrarı"
              label-for="confirm-password"
            >
              <b-form-input
                id="confirm-password"
                autocomplete="on"
                type="password"
                v-model="userData.confirmPassword"
                :state="userData.confirmPassword !== userData.password ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Buttons -->
          <div class="d-flex justify-content-end">
            <b-button
            variant="primary"
            type="submit"
            class="mr-2"
            @click.prevent="validationForm"

          
          >
            Kaydet
          </b-button>
            <b-button variant="outline-secondary" type="reset" >
              Temizle
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {getUserData} from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {ApiFormData} from '@/helpers/Api/apiConfigFormData'
import { Api } from '@/helpers/Api/apiConfig'

import { BFormRadioGroup,BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,BFormSelect } from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  required,length,email
} from '@validations'


export default {
    directives: {
    Ripple,
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSidebar,
    ValidationObserver,
    ValidationProvider,
    BFormRadioGroup,
    BFormSelect
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },

  },
  data() {
    return {
      userRole: getUserData(),
        showPassword: false,
        
      //   roles: [
      //   { text: 'Evet', value: true, disabled: false },
      //   { text: 'Hayır', value: false, disabled: false },
      
      // ],
      isManager: false,
      selectedDepartment: null,
      options: this.departmentList,

    //     name: '',
    //   passwordValue: '',
    //   passwordCon: '',
    //   emailValue: '',
    //   number: '',
    //   numberRange: '',
    //   numberRegx: '',
    //   URL: '',
    //   Alphabetic: '',
    //   digitValue: '',
    //   digitValue2: '',
    //   character: '',
      required,
    //   confirmed,
    //   password,
    //   email,
    //   min,
    //   integer,
    //   url,
    //   alpha,
    //   between,
    //   digits,
       length,
       email,
    //   alphaDash,
      userData: {
        name: '',
        surname: '',
        email: '',
        phoneNo: '',
       // image: '',
        generalRole: false,
        departmentRole:false
        
      },
    };
  },

created(){
  this.resetForm()
},
//   watch:{
//     departmentListt(val){
//       alert(val)
//     }
//   },
   
  methods: {
   async addNewUser(){
        const data = {
          name:this.userData.name,
          surname:this.userData.surname,
          email:this.userData.email,
          phoneNo:this.userData.phoneNo,
          timeLoop:10,
          generalRole:this.userData.generalRole?1:2,
          departmentId:this.selectedDepartment,
          departmentRole:this.userData.departmentRole?1:2



        }
      // fd.append("Name", this.userData.name);
      // fd.append("Lastname", this.userData.lastname);
      // fd.append("Password", this.userData.password);
      // fd.append("Email", this.userData.email);

      // fd.append("Phone", this.userData.phone);
      // fd.append("Image", null);
      // fd.append("TimeLoop", 10);
      // fd.append("IsAdmin", this.userData.isAdmin);



  
      const response = await Api('POST','User/Register',data)
      console.log('response new user',response);
if(response.data.message == "OK"){
   //let userId = response.data.data.id
      console.log('new user added',response.data);
    //  this.addNewUserToDepartment(userId,this.selectedDepartment,this.isManager)
      alert('Kayıt Başarılı!')
      this.$refs.mySidebar.visible = false
      this.$router.push({ name: 'user-settings' })
}else if(response.data.message=="ALREADY_EXIST"){
  alert('Kayıt Başarısız!:KULLANICI KAYITLI')
  this.resetForm()

 // this.$refs.mySidebar.visible = false

}else{
  alert('Kayıt Başarısız!:'+`${response.data.message}`)
 this.resetForm()

 //this.$refs.mySidebar.visible = false
}
     
   
    },
    isValidPhoneNumber(phoneNumber) {
      // Telefon numarasının sadece rakamlardan oluştuğunu kontrol etmek için bir düzenli ifade kullanabilirsiniz.
      // Örneğin, 11 haneli bir telefon numarası için /^\d{11}$/ düzenli ifadesini kullanabilirsiniz.

      const regex = /^\d{11}$/; // 11 haneli bir telefon numarası için düzenli ifade

      return regex.test(phoneNumber);
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        console.log('geldi ',success,this.$refs.simpleRules);
        if (success) {
          // eslint-disable-next-line
          this.onSubmit()
          // alert('form submitted!')
        }else{
          alert('Hatalı giriş değeri var!')
        }
      })
    },
    onSubmit() {
        this.addNewUser()

      // Handle form submission here
      // alert('Form submitted successfully!')
    },
    resetForm() {
      this.userData = {
        name: '',
        surname: '',
        email: '',
        phoneNo: '',
       // image: '',
        generalRole: false,
        departmentRole:false
       
      }
      this.selectedDepartment=null
      // Reset form
    },
   async addNewUserToDepartment(userId,departmentId,isManager){
   // this.isLoading = true
   console.log('222',userId,departmentId,isManager);
         await Api('POST','DepartmentUsers',{departmentId:departmentId,userId:userId,isManager:isManager})
          .then(response => {
              console.log('new user departman kaydı',response.data.data);
              // this.items = response.data.data
              response.data.message == "OK" ? this.resetForm(): alert('Kayıt Başarısız!',response.data.message)
              res
          })
          .catch(error => {
              console.log(error)
          })
        //  this.isLoading = false
   }
    // getValidationState(validationContext) {
    // //    console.log('validationContext', validationContext);
    //   if (validationContext && this.validationContext.dirty && this.validationContext.errors.length > 0) {
    //     return false;
    //   }
    //   return null;
   
    // },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>