<template>
    <div>
     
        <UserListAddNew
      v-if="isLoading === false"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
      :departmentList="departmentList"
   
    />
   <!-- {{ departmentList }} -->
   
  
    <b-row >
        <b-col
        md="12"
        sm="4"
        class="my-1 text-right"
      > 
         <b-button
   
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Yeni Kişi Ekle</span>
              </b-button>
            </b-col>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Liste adeti</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="8"
        class="my-1"
      >
        <b-form-group
          label="Departman"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
        <!-- {{ selectedDepartment }} -->
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="selectedDepartment"
              :options="sortOptions"
              value="value"
              class="w-75"
            >
              <template v-slot:seceond>
                <!-- <option value="''">
               
                </option> -->
              </template>
            </b-form-select>
            <!-- <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select> -->
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="my-1"
      >
        <b-form-group
          label="Ara"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Ad ile arama yapın..."
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Temizle
              </b-button>
            </b-input-group-append>
          </b-input-group>
         
        </b-form-group>
      </b-col>
  
      <b-col cols="12">
        <div class="d-flex justify-content-center mb-1 py-5" variant="primary" v-if="isLoading">
      <strong>Yükleniyor...</strong>
      <b-spinner class="m-auto" variant="primary" />
    </div>
        <b-table
        v-if="!isLoading"
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered(items)"
          @row-clicked="rowClicked"
         
        >
        <!-- <template #cell(image)="data">
  <b-avatar :src="`${baseUrl}+${data.item.user.image}`" />
</template> -->
 
          <template #cell(role)="data">
         
            <b-badge variant="primary">
              {{ data.item.user.generalRole == 'Admin' ?'Admin':data.item.departmentRole == 'DepartmentManager' ?'Deparmant yöneticisi':'Çalışan' }}
            </b-badge>
          </template>
          <template #cell(productivity)="data">
            <!-- data.item.efficiencyInformation.efficient -->
          <b-progress
         
            :value=" data.item.departmentUserDayBasedProductivity.efficientLast365Day.efficientPercentage"
            max="100"
            variant="data.value.variant"
            striped
          />
          {{ data.item.departmentUserDayBasedProductivity.efficientLast365Day.efficientPercentage + '%' }}
          <!-- {{ 80 + '%' }} -->
        </template>
           <!-- Column: Action -->
           <template #cell(düzenle)="data">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span @click="rowClicked(data.item)">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
        </span>
    </template>
        </b-table>
      </b-col>
  
      <b-col
        cols="12"
      >
        <b-pagination
        v-if="!isLoading"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
</div>
  </template>
  
  <script>
import { BASE_URL } from '@/helpers/Url/BaseUrl'
import { mapActions } from 'vuex';


  import { Api } from '@/helpers/Api/apiConfig'
import UserListAddNew from '@/layouts/components/AddNewUser.vue'



  import {
    BDropdown,BProgress, BDropdownItem, BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BSpinner
  } from 'bootstrap-vue'
  
  export default {
  //   beforeRouteEnter(to, from, next) {
  //   const userData = localStorage.getItem('userData')
  //   alert(userData)
  //   if (userData.isAdmin) {
  //     next()
  //   } else {
  //     next({ name: 'error-404' })
  //   }
  // },
    
    components: {
      BProgress,
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BDropdownItem,
      BDropdown,
      UserListAddNew,
      BSpinner
      
    },
    data() {
      return {
        
        isLoading: false,
        departmentList: [],
      roleOptions: [
        { text: 'Admin', value: 1 },
      ],
      planOptions: [
        { text: 'Basic', value: 1 },
      ],
      refetchData:'',
        isAddNewUserSidebarActive: false,
        baseUrl: BASE_URL,
        selectedDepartment: '',
        perPage: 5,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          // {
          //   key: 'user.userId', label: 'Id',
          // },
          // {
          //   key: 'image', label: 'Avatar',
          // },
          { key: 'user.name', label: 'Ad', sortable: false },
          { key: 'user.surname', label: 'Soyad', sortable: false },
          { key: 'department.name', label: 'Departman', sortable: false },
          
          
          { key: 'user.email', label: 'email', sortable: false },
          { key: 'user.phoneNo', label: 'phone', sortable: false },
          { key: 'role', label: 'Yetki', sortable: false },
          { key: 'productivity', label: 'Verim', sortable: false },
           'Düzenle',
        //   { key: 'department.name', label: 'experience', sortable: true },
          

        // //   'user.email',
        // //   'user.email',
        // //   'user.email',
        // //   'user.email',
        // //   'user.email',
        // //   'user.email',
        //   { key: 'department.name', label: 'Status', sortable: true },
        ],
        items: [
        
        ],
        status: [{
          1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
        },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
      }
    },
    watch: {
      isLoading(val) {
        this.isLoading = val
      },
      selectedDepartment() {
        this.getDepartmentUsers(this.selectedDepartment)
      }
    },
    computed: {
      ...mapActions('userData', 'updateUserData'),

      sortOptions() {
            
        // Create an options list from our fields
        return this.departmentList
         // .filter(f => f.sortable)
          .map(f => ({ text: f.text, value: f.value }))
      },
    },
    mounted() {
        this.getDepartmentsDetail()

      // Set the initial number of items
    

    },
    methods: {
      rowClicked(item, index, event){
        let userData=item.user

        console.log('event',item,index,event);
     
         this.$store.commit('settingsUser/setUserData',userData)
       
          this.$router.push({name:'user-edit',params:{id:item.user.userId}})

        
        
      },
        async getDepartmentsDetail() {
      this.isLoading = true;
      await Api("GET", "Department/GetAllActiveDepartments").then((res) => {
        this.departments = res.data.data.activeDepartments;
        this.departmentList = this.departments.map((item) => {
          return {
            text: item.name,
            value: item.departmentId,
            //isActive:false
          };
        });
       this.departmentList.unshift({
          text: 'Tüm Departmanlar',
          value: '',
       // isActive:true
        }) 
        // this.$emit('departmentList',this.departmentList)
        console.log( 'userrdepartments',this.departments);
       
       
      });
      this.isLoading = false;
      this.getDepartmentUsers(this.selectedDepartment)

    },
        async getDepartmentUsers(departmentId){
          this.isLoading = true;
     

           await Api('GET','DepartmentUser?departmentId='+departmentId)
            .then(response => {
                console.log('deparmentUsers',response.data.data);
                this.items = response.data.data.items//.map(user=>{return user.user})
                this.totalRows = this.items.length
     

            })
            .catch(error => {
       

                alert(error)
            })
            this.isLoading = false;
        },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)

        console.log('info',item, index, button);
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    },
  }
  </script>
   