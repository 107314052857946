<template>
  
    <b-card @mouseover="isHovered = true" @mouseleave="isHovered = false" v-if="isActive === 0 ? true : isActive === 1 ? user.isOnline : isActive === 2 ? !user.isOnline : true" class="mt-2">
      <b-row  class="pl-0">
        <div class="col-6">
 <label for="user-info">
      <b>{{ capitalizeName(user.departmentUser.user.name) + ' ' + capitalizeName(user.departmentUser.user.surname) }}</b>
    </label>
        </div>
        <div class="col-6 ">
             
             <div class="d-flex flex-wrap float-right">
               <b-spinner :variant="user.isOnline? variants[0] : variants[1]" class="mr-1" type="grow"
                 small />
             </div>
            
             <div class="float-right mr-1 text-right">
               {{ user.isOnline?"Açık":"Kapalı" }}
               <!-- <b-dropdown :text="selectedPeriod" variant="transparent" class="chart-dropdown float-right " center
                 no-caret size="sm">
                 <b-dropdown-item v-for="day in avgData.lastDays" :key="day" v-model="selectedPeriod"
                   @click="selectedPeriod = day">
                   {{ day }}
                 </b-dropdown-item>
               </b-dropdown> -->
             </div>
           </div>
           
</b-row> <hr style="margin:0px">
      <b-row class="pb-50 pt-1">
        <!-- ss{{ user }} -->
        <!-- text and button -->

        <b-col lg="12" order-lg="1" order="2" class="d-flex justify-content-between flex-column">


          <div class="row " :class="user.image.length > 1 ? 'mt-0 mb-1' : 'row mb-1 mt-0'">
            <div class="col-6">
              <!-- <div class="card-title" size="sm"> CN: <span style="color: orange;font-size: 1rem;">Eralp_5</span> </div> -->
              <!-- v-if="user.image.length > 1"  -->
             <!-- {{ user.image }} -->
              <b-button class="btn btn-xs ml-1  "  v-for="(item, index) in user.image" :key="index" :class="{ activeScreenBtn: index == selectedScreenValue }" 
                @click="selectedScreen(index)"> <feather-icon icon="MonitorIcon" /> {{ index + 1 }}
             
              </b-button>
          
            </div>
           
            <div class="col-6 ">
              <b-card-text class="mb-50 text-right" style="font-size: 0.8rem;">


Aktif Süre: <b>{{ formattedTime.hours }}</b>s : <b>{{ formattedTime.minutes }}</b>dk : <b>{{
formattedTime.seconds }}</b>sn
</b-card-text>
<!-- <b-progress value="40" max="100" variant="success" height="6px" class="mt-25" /> -->
              <!-- <div class="d-flex flex-wrap float-right">
                <b-spinner :variant="user.isOnline? variants[0] : variants[1]" class="mr-1" type="grow"
                  small />
              </div>
             
              <div class="float-right mr-1 text-right">
                {{ user.isOnline?"Açık":"Kapalı" }}
              
              </div> -->
            </div>


          </div>
                  <!-- dd {{baseUrl+maxImg }}
  dd {{baseUrl+minImg }} -->
          <!-- {{isModalOpen}} -->
          <div v-if="user.image.length > 0">

            
            <!-- <div class="col-2">
        <b-button-toolbar>
          <b-button-group size="sm">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon" @click="value2 = 4">
              <feather-icon icon="MonitorIcon" />
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 3"> 4 </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 4"> 3 </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 6"> 2 </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 12"> full </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div> -->
            <img :src="`${baseUrl + user.minImage[selectedScreenValue]}`" alt="Image" @click="openModal" class="w-100 "
              :class="user.image.length > 0 ? 'img-min cursor' : 'img-min'" @error="(e) => e.target.src = require('@/assets/vagsis_logo.png')" />

          </div>
          <div v-else>
            <img :src="require('@/assets/vagsis_logo.png')" alt="Image" @click="openModal" class="w-100 mt-0"
              :class="user.image.length > 0 ? 'img-min cursor' : 'img-min'" @error="(e) => e.target.src = require('@/assets/vagsis_logo.png')"/>

          </div>
          <!-- <button v-if="isModalOpen" @click="closeModal">Modal Butonu</button> -->

          <b-modal v-model="isModalOpen" v-if="user.image" class="modal-content" id="modal-xl" ok-only ok-title="Kapat"
            centered size="xl"
            :title="'Kullanıcı: ' + user.departmentUser.user.name + ' ' + user.departmentUser.user.surname">
            <b-button class="btn btn-sm ml-1 mb-1"  :class="{ activeScreenBtn: index === selectedScreenValue }"  v-for="(item, index) in user.image" :key="index" v-if="user.image.length > 1"
              @click="selectedScreen(index)">{{ index + 1 }}</b-button>

            <img
              :src="user.image.length > 0 ? baseUrl + user.image[selectedScreenValue] : require('@/assets/vagsis_logo.png')"
              alt="Image" class="img-big" @error="(e) => e.target.src = require('@/assets/vagsis_logo.png')" />
          </b-modal>


        </b-col>
      </b-row>
    
      <b-row v-show="isHovered" class="w-100 card-text text-center justify-content-center m-0 pb-0 pb-1" style="font-size: 10px;    min-height: 2vh;
    max-height: 3vh; text-align: center !important;justify-content: center !important;margin:0px auto">
        <label class="justify-content-center m-0 p-0 w-100" style="color: orange;">Aktif Program </label> <br> 
        <p >{{ getTruncatedText(user) }}</p>
        <!-- {{ user.image ?user.departmentProgram.program.tag + ' - ' + user.screenTitle : '' }} -->
     <!-- <label v-else><br>Aktif program bilgisi yok!</label> -->
      </b-row>
      <hr v-show="isHovered" >
      <b-row  class="w-100 card-text text-center justify-content-center m-0 p-0" style="font-size: 11px;    min-height: 2vh;
    max-height: 3vh; text-align: center !important;justify-content: center !important;margin:0px auto">
        <label class="justify-content-center m-0 p-0 w-100" style="color: orange;">Aktif Proje </label> <br> 
        <p >{{ getTruncatedProjectName(user) }}</p>
        <!-- {{ user.image ?user.project.name: '' }} -->
     <!-- <p v-else ><br>Aktif proje bilgisi yok!</p> -->
      </b-row>
      <hr v-show="isHovered" >

      <!-- progress bar -->
      <b-row v-show="isHovered" class="avg-sessions pt-50 small">
        <b-col cols="5" md="5" sm="12"  style="border-right: 1px solid grey;">
          <b-card-text class="mb-50">
            <!-- Detay:<br> -->
            <router-link :to="`/user/${user.departmentUser.user.userId}`"> <b-button variant="primary"
                class="btn-tour-finish" size="md">Profile Git</b-button> </router-link>
          </b-card-text>
        </b-col>
        <b-col cols="7" md="7" sm="12"  >
          <b-card-text class="mb-50 inline-items">
            <!-- {{ user }} -->
            <p>            Yetki: <br> {{ user.departmentUser.user.generalRole !== 'User' ? 'Yönetici' : 'Çalışan'}}
            </p><br>
<p class="pl-2">
  Departman:<br> {{ user.departmentUser.department.name }}

</p>
          </b-card-text>
          <!-- <b-progress :value="user " max="100" height="6px" variant="warning" /> -->
        </b-col>
        <!-- <b-col cols="6" md="6" sm="12" >
        
         
        </b-col>
        <b-col cols="6" md="6" sm="12" v-show="isHovered">
        
        </b-col> -->
      </b-row>
     
    </b-card>
  

</template>

<script>
import {
  BCard, BRow, BCol, BButton, BDropdown,BCardCode, BDropdownItem, BProgress, BCardText,  BModal, VBModal
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'

import { Api } from '@/helpers/Api/apiConfig'
import { BASE_URL } from '@/helpers/Url/BaseUrl'
import { BSpinner } from 'bootstrap-vue'
import user from '@/views/pages/settings/users-edit/user.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardCode,
    BSpinner,
    BModal,
    BASE_URL

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      isHovered: false,

      selectedScreenValue: 0,
      minImg: '',
      maxImg: '',
      isModalOpen: false,
      // spinner
      variants: ['success', 'danger'],
      isActive: 0,
      selectedPeriod: 'Canlı',
      baseUrl: BASE_URL,

      // end
      avgData: {
        lastDays: {
          Haftalık: 'Haftalık',
          Aylık: 'Aylık',

          Yıllık: ' Yıllık',
        }
      },
      salesBar: {
        series: [
          {
            name: 'Sessions',
            data: [75, 125, 225, 175, 125, 75, 25],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: 'none',
            },
          },
          colors: [
            '#ebf0f7',
            '#ebf0f7',
            $themeColors.primary,
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              endingShape: 'rounded',
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: 'numeric',
          },
        },
      },
      // video record
      imageUrl: '',
      mediaRecorder: null,
      chunks: [],
      timer: null,
      // video record end
    }
  },
 
  watch: {
    // selectedScreenValue(val) {
    //   this.selectedScreenValue = val
    // },
    imgMin(val) {
      this.minImg = val
    },
    imgMax(val) {
      this.maxImg = val
    },
    isModalOpen(val) {
      this.isModalOpen = val
    },
    status(val) {
      this.isActive = val
    },

  },
  props: {
    // imgMin: {
    //   type: String,
    //   required: true
    // },
    // imgMax: {
    //   type: String,
    //   required: true
    // },
    status: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedTime() {
      console.log('sfsfdsfdf', this.user.activeTime)
      const time = this.user.activeTime;
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = this.user.activeTime % 60;
      // console.log('hours',hours,minutes,seconds);
      return { hours, minutes, seconds };
    }
  },
  methods: {
    getTruncatedText(user) {
      const fullText = user.isOnline ? user.departmentProgram.program.tag + ' - ' + user.screenTitle : 'Aktif program bilgisi yok!';
      return fullText.length > 50 ? fullText.slice(0, 50) + "..." : fullText;
    },
    getTruncatedProjectName(user) {
      const projectName = user.isOnline ? user.project.name : 'Aktif proje bilgisi yok!';
      return projectName.length > 50 ? projectName.slice(0, 50) + "..." : projectName;
    },
    capitalizeName(name) {
      return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    },
    selectedScreen(index) {
      this.selectedScreenValue = index
    },
    kFormatter,
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }


  }
  // video record end

}
</script>
<style lang="scss">
.card-text p{
  line-height: 1rem;
}
.card-body {
  padding: 1rem;
}
@media (min-width: 768px) {
  .modal-content {
    width: 100%;
    height: 100vh;
  }
}

.modal-title {
  color: orange !important;

}

.img-big {
  width: 100%;
  height: 100vh;
  object-fit: contain;
}

.img-min {
  height: 256px;
  object-fit: none;
  border: 1px solid #7366F0;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 11px 0px #7366F0;
}

.cursor {
  cursor: pointer;
  height: 256px;
  object-fit: fill;
}
.activeScreenBtn{
  
    background-color: #7367F0 !important;
    color: white !important;
}
.inline-items {
  display: flex;
  align-items: center;
}

.inline-items p {
  margin-right: 10px; /* Adjust the spacing between the items */
  margin-bottom: 0px;
}
.btn-xs {
  padding: 0.3rem 0.8rem;
  font-size: 0.65rem;
  line-height: 1rem;
}
</style>