<template>
  <div>
    <!-- {{ userData }} -->
    <div class="d-flex justify-content-center mb-1" variant="warning" v-if="isLoading">
      <strong>Yükleniyor...</strong>
      <b-spinner class="m-auto" variant="warning" />
    </div>
   
    <div class="row" v-if="!isLoading">
      <div class="col-md-2 col-sm-12">
        <b-button-toolbar>
          <b-button-group size="sm">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon" @click="value2 = 4">
              <feather-icon icon="MonitorIcon" />
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 3"> 4 </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 4"> 3 </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 6"> 2 </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="value2 = 12"> full
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>

      <div class="col-md-2 col-sm-12">
        <!-- <vue-slider
      v-model="value2"
      :adsorb="true"
      :data="data"
      :marks="false"
      class="mb-3"
      :direction="direction"
    /> -->
      </div>
      <div class="col-md-4 col-sm-12">
        <b-form-group>
          <b-form-radio-group v-model="btnStatus" :options="optionsStatus" class="demo-inline-spacing"
            name="radio-inline" />
        </b-form-group>
      </div>
<!-- {{ option[0].departmentUser.user }} -->
      <div class="col-4" >
        <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" getop
          :options="option.map(user => user.departmentUser.user)" placeholder="Kullanıcı Ara" >
          <template #option="{ name, surname }" >
            <p style="margin: 0; text-transform: capitalize">{{ name }} {{ surname }}</p>
            
          </template>
        </v-select>
      </div>


      <!-- {{ btnStatus }} -->
      <div class="row container m-auto w-100">
        <!-- {{ userInfo }} -->

        <div class="col-lg-3 col-md-6 col-sm-12" v-if="!isLoading" :class="'col-lg-' + screenRatio"
          v-for="(userData, index) in userInfoFiltered" :key="index">
          <!-- user in departmanUsers -->
          <!-- :imageUrl="imageUrl" :imageUrlBig="imageUrlBig"  -->
          <userCard :user="userData" :status="btnStatus" :imgMin="imgUrlsMin[index]" :imgMax="imgUrlsMax[index]" />
        </div>
        <div v-if="userInfoFiltered.length<1">
          <h4>Kayıtlı kullanıcı bulunamadı</h4>
          
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import userCard from "@/components/Home/CardAnalyticAvgSessions.vue";
import { Api } from "@/helpers/Api/apiConfig";
//import { BASE_URL } from '@/helpers/Url/BaseUrl'
import { BButtonToolbar, BButtonGroup, BButton, BFormRadioGroup, BFormGroup, BAvatar, BCardText } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import store from "@/store/index";
import VueSlider from "vue-slider-component";
import { BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    userCard,
    VueSlider,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BSpinner,
    BFormGroup,
    BFormRadioGroup,
    VueAutosuggest,
    BAvatar,
    BCardText,
    vSelect,
  },

  data() {
    return {

      ////just img send prop//
      imgUrlsMin: [],
      imgUrlsMax: [],
      //////

      ////search////////
      selected: "",
      option: [],
      searchItem: "",
      //////////
      /////////online/offline-all switch
      btnStatus: 0,
      optionsStatus: [
        { text: "Tümü", value: 0 },
        { text: "Açık", value: 1 },
        { text: "Kapalı", value: 2 },
      ],
      ///////
      //screen ratio
      isLoading: false,
      value2: 4,

      data: [3, 4, 6, 12],
      // marks: [3,3, 4, 4],
      dir: "ltr",

      // end
      selectedDepartment: null,
      departmanUsers: [],
      userInfo: [],
      screenRatio: 4,
      // departments:[]
      // video record
      imageUrl: "",
      imageUrlBig: "",
      mediaRecorder: null,
      chunks: [],
      timer: null,
      // video record end
    };
  },
  directives: {
    Ripple,
  },

  computed: {
    userData() {
      const data = getUserData()
      return data.generalRole === 'User' && data.departmentUser.some(item => item.departmentRole === 'DepartmentWorker')
    },
    userInfoFiltered() {

      if (this.btnStatus === 0) return this.userInfo.map(user => user)
      else if (this.btnStatus === 1) return this.userInfo.filter((user) => user.isOnline)
      else if (this.btnStatus === 2) return this.userInfo.filter(user => !user.isOnline)
      else return this.userInfo.map(user => user)

    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
  async beforeDestroy() {
    console.log("beforeDestroy");
    await this.stopRecording();
  },
  unmounted() {
    console.log("çalıştı");
    this.stopRecording();
  },
  watch: {
    userData() {
     this.checkOnlyWorker();
    },
    "$route.name": {
      handler: function (value) {
        //  alert(value);
        console.log("ne buu", value);
        // productivity route'ı ise burada yapılacak işlemler
        this.$route.name !== "home" ? this.stopRecording() : "";
      },
      deep: true,
      immediate: true,
    },
    userInfo(val) {
      this.userInfo = val;

    },
    "$route.query.department": {
      handler: function (value) {
        console.log("ne buu2", value);
        this.stopRecording() 
        this.selectedDepartment = value
        this.makeRequest()
        this.startRecording();
      },
      deep: true,
      immediate: true,
    },
    value2(val) {
      this.screenRatio = val;
    },
    selected(val) {
      console.log('oz',val)
      val ? (this.searchItem = val.name) : (this.searchItem = "");
      this.makeRequest();
    },
    searchItem(val) {
      this.makeRequest();
    },
    btnStatus(val) {
      this.btnStatus = val;


    },
  },

  mounted() {
    this.checkOnlyWorker();

    this.makeRequest()
     this.startRecording();

  },

  methods: {
    checkOnlyWorker() {
      if(this.userData) {
        this.$router.push(`/user/${getUserData().userId}`);
      }
    },
    checkStatusUser() {


      return this.userInfo.filter((item) => {
        return this.btnStatus == 0 ? item.image : this.btnStatus == 1 ? item.image.length > 0 : this.btnStatus == 2 ? item.image.length < 1 : item
      })

    },
    kFormatter,
    // video record
    async startRecording() {
      this.isLoading = true;
      console.log("burası kaç kez çalışıyor.", this.timer);
      if (this.timer != null) return;
      this.timer = setInterval(() => {
        this.makeRequest();
      }, 10000);
    },
    async stopRecording() {
      clearInterval(this.timer);
      console.log("stopped recording çalıştı.", this.timer);
      this.timer = null;
      this.isLoading = false;
    },
    restartRecording() {
      this.stopRecording();
      this.startRecording();
    },
    async makeRequest() {
      if(this.userInfo.length === 0)
      this.isLoading = true;
      console.log("makeRequest çalıştı",this.searchItem);
      await Api("GET", "Log/Onlinelog" + (this.selectedDepartment ?"?departmentId="+this.selectedDepartment:'?') + "&search="+this.searchItem)
        .then((res) => {
          console.log("resff", res);
          this.userInfo = []
          // this.imgUrlsMin = res.data.data.items.minImage.map((item) => {
          //   return item.minImage ? item.minImage[0] : "";
          // });
          // this.imgUrlsMax = res.data.data.items.image.map((item) => {
          //   return item.image ? item.image[0] : "";
          // });

  this.userInfo = res.data.data.items

          
          // .map((item) => {

          //  return  item.departmentUser.user

          // })

          this.option = this.userInfo


          console.log("userInfo", this.userInfo);

          this.isLoading = false;



        })
        .catch((error) => {
          console.error(error);
        });


    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
